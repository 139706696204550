/* App.css */

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  padding: 0;
  background: #0e0e0e;
  color: #00ff99;
  font-family: 'IBM Plex Mono', 'Courier New', Courier, monospace;
  height: 100vh;
  overflow: hidden;
  position: relative;
}


.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 80vh;
}

/* .counter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */

.glitch {
  font-size: 2.5vh;
  position: relative;
  color: #ffc0cb;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.glitch-text {
  position: relative;
}

.glitch-text::before,
.glitch-text::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffc0cb;
  background: transparent;
  overflow: hidden;
  clip-path: inset(0 0 0 0);
}

.glitch-text::before {
  text-shadow: -2px 0 red;
  animation: glitch-animation 3s infinite;
}

.glitch-text::after {
  text-shadow: -2px 0 blue;
  animation: glitch-animation 3s infinite reverse;
}

@keyframes glitch-animation {
  0% {
    clip-path: inset(80% 0 0 0);
    transform: translate(-2px, -2px);
  }
  10% {
    clip-path: inset(10% 0 85% 0);
    transform: translate(2px, 2px);
  }
  20% {
    clip-path: inset(20% 0 75% 0);
    transform: translate(-2px, 0);
  }
  30% {
    clip-path: inset(30% 0 65% 0);
    transform: translate(0, 2px);
  }
  40% {
    clip-path: inset(40% 0 55% 0);
    transform: translate(-2px, -2px);
  }
  50% {
    clip-path: inset(50% 0 45% 0);
    transform: translate(2px, 0);
  }
  60% {
    clip-path: inset(60% 0 35% 0);
    transform: translate(0, -2px);
  }
  70% {
    clip-path: inset(70% 0 25% 0);
    transform: translate(-2px, 2px);
  }
  80% {
    clip-path: inset(80% 0 15% 0);
    transform: translate(2px, -2px);
  }
  90% {
    clip-path: inset(90% 0 5% 0);
    transform: translate(-2px, 0);
  }
  100% {
    clip-path: inset(0 0 100% 0);
    transform: translate(0, 2px);
  }
}





